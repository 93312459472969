import { CreateStyled } from "@emotion/styled";

export const focusInput = (name: string) => {
  const input = document.querySelector(
    `input[name=${name}]`
  ) as HTMLInputElement;
  input.focus();
};

// https://www.freecodecamp.org/news/check-if-a-javascript-string-is-a-url/
export const URLRegexValidator = new RegExp(
  "^(https?:\\/\\/)?" + // validate protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
    "(\\#[-a-z\\d_]*)?$",
  "i"
);

export const withTransientProps: Parameters<CreateStyled>[1] = {
  shouldForwardProp: (propName: string) => !propName.startsWith("$"),
};

export function setItemWithExpiry(key: string, value: string, ttl = 3600000) {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getItemWithExpiry(key: string) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  try {
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  } catch (e) {
    localStorage.removeItem(key);
    return null;
  }
}

export const getSourceParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const email = searchParams.get("email");
  const utm_source = searchParams.get("utm_source");
  const utm_medium = searchParams.get("utm_medium");
  const utm_campaign = searchParams.get("utm_campaign");
  const gclid = searchParams.get("gclid");
  const fbclid = searchParams.get("fbclid");
  const msclkid = searchParams.get("msclkid");
  return {
    email,
    utm_source,
    utm_medium,
    utm_campaign,
    gclid,
    fbclid,
    msclkid,
  };
};

export function getCarrier(carrierData: string): string {
  if (carrierData === undefined || carrierData === null || carrierData === "") {
    return "";
  }
  let carrier;
  switch (true) {
    case carrierData.toLowerCase().includes("awn"):
      carrier = "ais";
      break;
    case carrierData.toLowerCase().includes("bouygues"):
      carrier = "bouygues";
      break;
    case carrierData.toLowerCase().includes("free"):
      carrier = "free";
      break;
    case carrierData.toLowerCase().includes("telefonica"):
      carrier = "o2";
      break;
    case carrierData.toLowerCase().includes("orange"):
      carrier = "orange";
      break;
    case carrierData.toLowerCase().includes("radiotelephone"):
      carrier = "sfr";
      break;
    case carrierData.toLowerCase().includes("3"):
      carrier = "three";
      break;
    case carrierData.toLowerCase().includes("vodafone"):
      carrier = "vodafone";
      break;
    case carrierData.toLowerCase().includes("ee"):
      carrier = "ee";
      break;
    case carrierData.toLowerCase().includes("wind"):
      carrier = "wind";
      break;
    case carrierData.toLowerCase().includes("tim"):
      carrier = "tim";
      break;
    case carrierData.toLowerCase().includes("sosh"):
      carrier = "sosh";
      break;
    case carrierData.toLowerCase().includes("iliad"):
      carrier = "iliad";
      break;
    case carrierData.toLowerCase().includes("poste"):
      carrier = "la-poste";
      break;
    case carrierData.toLowerCase().includes("lycamobile"):
      carrier = "lycamobile";
      break;
    case carrierData.toLowerCase().includes("swisscom"):
      carrier = "swisscom";
      break;
    case carrierData.toLowerCase().includes("proximus"):
      carrier = "proximus";
      break;
    case carrierData.toLowerCase().includes("digicel"):
      carrier = "digicel";
      break;
    case carrierData.toLowerCase().includes("bell"):
      carrier = "bell-mobility";
      break;
    case carrierData.toLowerCase().includes("virgin"):
      carrier = "virgin";
      break;
    case carrierData.toLowerCase().includes("onetel"):
      carrier = "onetel";
      break;
    case carrierData.toLowerCase().includes("tmobile"):
      carrier = "tmobile";
      break;
    default:
      carrier = "";
  }
  return carrier;
}

export const getErrorMessage = (error: any) => {
  if (typeof error === "string") {
    return error;
  }
  if (typeof error?.response?.data?.error === "string") {
    return error.response.data.error;
  }
  if (typeof error?.message === "string") {
    return error.message;
  }
  return "An error occurred";
};

export function formatBytes(
  bytes: number,
  options: { useBinaryUnits?: boolean; decimals?: number } = {}
): string {
  const { useBinaryUnits = false, decimals = 0 } = options;

  if (decimals < 0) {
    throw new Error(`Invalid decimals ${decimals}`);
  }

  const base = useBinaryUnits ? 1024 : 1000;
  const units = useBinaryUnits
    ? ["Bytes", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"]
    : ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(base));

  return `${(bytes / Math.pow(base, i)).toFixed(decimals)} ${units[i]}`;
}
